import { HttpLogin } from "../Http";

export class CreateNotesAPI {
    public static createNotes(org: any) { 
    var url = ""                
      var obj = JSON.stringify(org);
     
        const resultMethod =   HttpLogin.axios().post(url,obj, {
              headers: { 
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "*",
                "Access-Control-Allow-Headers": "*"
              }
            })
            .then(res => {       
                 return res;   
            })
            .catch((e: any) => {
              return e;               
            });
                 return resultMethod;
      }
  }

  export class UpdateNotesAPI {
    public static updateNotes(org: any) { 
    var url = ""                
      var obj = JSON.stringify(org);
     
        const resultMethod =   HttpLogin.axios().put(url,obj, {
              headers: { 
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "*",
                "Access-Control-Allow-Headers": "*"
              }
            })
            .then(res => {       
                 return res;   
            })
            .catch((e: any) => {
              return e;               
            });
                 return resultMethod;
      }
  }

  export class GetAllNotesAPI {
    public static getAllNotesData(org: any) { 
        var url = "";   

     
        const resultMethod =   HttpLogin.axios().get(url, {
              headers: { 
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "*",
                "Access-Control-Allow-Headers": "*"
              }
            })
            .then(res => {       
                 return res;   
            })
            .catch((e: any) => {
              return e;               
            });
            return resultMethod;
      }
  
  }

  export class GetByIdNotesAPI {
    public static getByIdNotesData(org: any) { 
        var url = "";   
     
        const resultMethod =   HttpLogin.axios().get(url, {
              headers: { 
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "*",
                "Access-Control-Allow-Headers": "*"
              }
            })
            .then(res => {       
                 return res;   
            })
            .catch((e: any) => {
              return e;               
            });
            return resultMethod;
      }
  
  }

import React, { useState } from 'react';
import './Leftmenubar.css'
import plusImage from '../../assets/images/mettler_images/plusImage.png'
import { Tooltip } from '@mui/material';
const NavigationMenu = ({ menuItems }) => {
    const [activeItem, setActiveItem] = useState(null);

    const handleClick = (menuItem) => {
        setActiveItem(menuItem.title);
        window.location.href = menuItem.link;
    };
    return (

        <>
            <a className="dashbordHover" onClick={() => { window.location.href = "/MettlerAddOrganization" }}>
                <img style={{ height: '22px', width: "22px" }} alt="" src={plusImage} />
            </a>
            {menuItems.map((menuItem) => (
                <>
                    <Tooltip key={menuItem.title} title={menuItem.title} placement="right-start" arrow>
                        <a
                            className="dashbordHover"
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleClick(menuItem)}
                        >
                            <img style={{ height: '20px', width: '20px' }} alt="" src={menuItem.icon} />

                        </a>
                    </Tooltip>
                </>
            ))}
        </>
    );
};

export default NavigationMenu;
